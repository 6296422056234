import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="dwarven-defender"></a><h3>DWARVEN DEFENDER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG186_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d12.</p>
    <a id="dwarven-defender-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a defender, a character
must fulfill all the following criteria.</p>
    <p><b>Race</b>: Dwarf.</p>
    <p><b>Alignment</b>: Any lawful.</p>
    <p><b>Base Attack Bonus</b>: +7.</p>
    <p><b>Feats</b>: Dodge, Endurance, Toughness.</p>
    <h6>Class Skills</h6>
    <p className="initial">The defender&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense
Motive</a> (Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a> (Wis).</p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-dwarven-defender"></a><p><b>Table: The Dwarven Defender</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "10%"
          }}>AC Bonus</th>
          <th style={{
            "width": "50%"
          }}>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+2</td>
          <td>+1</td>
          <td>Defensive stance 1/day</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+3</td>
          <td>+1</td>
          <td>Uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>Defensive stance 2/day</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>+2</td>
          <td>Trap sense +1</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>+2</td>
          <td>Defensive stance 3/day</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Damage reduction 3/&#8211;, improved uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+3</td>
          <td>Defensive stance 4/day</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>+3</td>
          <td>Mobile defense, trap sense +2</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>+3</td>
          <td>Defensive stance 5/day</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+4</td>
          <td className="last-row">Damage reduction 6/&#8211;</td>
        </tr>
      </tbody>
    </table>
    <a id="dwarven-defender-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are Class Features of the
dwarven defender prestige class.</p>
    <a id="dwarven-defender-ac-bonus"></a><p><b>AC Bonus (Ex)</b>: The dwarven defender receives a dodge
bonus to Armor Class that starts at +1 and improves as the defender
gains levels, until it reaches +4 at 10th level.</p>
    <p><b>Weapon and Armor Proficiency</b>: A dwarven defender is
proficient with all simple and martial weapons, all types of armor, and
shields.</p>
    <a id="dwarven-defender-defensive-stance"></a><p><b>Defensive Stance</b>: When he adopts a defensive stance, a
defender gains phenomenal strength and durability, but he cannot move
from the spot he is defending. He gains +2 to Strength, +4 to
Constitution, a +2 resistance bonus on all saves, and a +4 dodge bonus
to AC. The increase in Constitution increases the defender&#8217;s hit points
by 2 points per level, but these hit points go away at the end of the
defensive stance when the Constitution score drops back 4 points. These
extra hit points are not lost first the way temporary hit points are.
While in a defensive stance, a defender cannot use skills or abilities
that would require him to shift his position. A defensive stance lasts
for a number of rounds equal to 3 + the character&#8217;s (newly improved)
Constitution modifier. A defender may end his defensive stance
voluntarily prior to this limit. At the end of the defensive stance,
the defender is winded and takes a &#8211;2 penalty to Strength for the
duration of that encounter. A defender can only use his defensive
stance a certain number of times per day as determined by his level
(see Table: The Dwarven Defender). Using the defensive stance takes no
time itself, but a defender can only do so during his action.</p>
    <p><b>Uncanny Dodge (Ex)</b>: Starting at 2nd level, a dwarven
defender retains his Dexterity bonus to AC (if any) regardless of being
caught flat-footed or struck by an invisible attacker. (He still loses
any Dexterity bonus to AC if immobilized.)</p>
    <p>If a character gains uncanny dodge from a second class, the
character automatically gains improved uncanny dodge (see below).</p>
    <p><b>Trap Sense (Ex)</b>: At 4th level, a dwarven defender gains
a +1 bonus on Reflex saves made to avoid traps and a +1 dodge bonus to
AC against attacks by traps. At 8th level, these bonuses rise to +2.
These bonuses stack with trap sense bonuses gained from other classes.</p>
    <a id="dwarven-defender-damage-reduction"></a><p><b>Damage Reduction (Ex)</b>: At 6th level, a dwarven defender
gains damage reduction. Subtract 3 points from the damage the dwarven
defender takes each time he is dealt damage. At 10th level, this damage
reduction rises to 6/&#8211;. Damage reduction can reduce damage to 0 but not
below 0.</p>
    <p><b>Improved Uncanny Dodge (Ex)</b>: At 6th level, a dwarven
defender can no longer be flanked. This defense denies rogues the
ability to use flank attacks to sneak attack the dwarven defender.</p>
    <p>The exception to this defense is that a rogue at least four
levels higher than the dwarven defender can flank him (and thus sneak
attack him).</p>
    <p>If a character gains uncanny dodge (see above) from a second
class the character automatically gains improved uncanny dodge, and the
levels from those classes stack to determine the minimum rogue level
required to flank the character.</p>
    <a id="dwarven-defender-mobile-defense"></a><p><b>Mobile Defense (Ex)</b>: At 8th level, a dwarven defender
can adjust his position while maintaining a defensive stance. While in
a defensive stance, he can take one 5-foot step each round without
losing the benefit of the stance.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      